<template>
  <fieldset
    class="checkbox_select"
    :name="
      type === 'licenses'
        ? 'licensesSelect'
        : type === 'instruments'
        ? 'instrumentsSelect'
        : ''
    "
    :class="showAll ? '--full_list' : ''"
    v-if="actualData && actualData.length > 0"
    :style="getCurrentMode === 'edit' ? 'max-height:' + minHeight + 'px' : null"
  >
    <input
      type="search"
      class="input__field"
      placeholder="Fast search"
      v-model="query"
      v-if="
        this.getCurrentMode !== 'review' && this.getSelectedTab.title !== 'logs'
      "
    />
    <span class="checkbox_select__label">
      {{ title }}
      <span class="input__required" v-if="type === 'instruments'">*</span>
    </span>
    <button type="button" class="btn" @click="showAll = !showAll">
      {{ showAll ? "Hide all" : "Show All" }}
    </button>
    <div class="checkbox_select__wrap" v-if="typeof actualData !== 'string'">
      <Checkbox
        v-for="(checkbox, index) in actualData"
        :key="
          index +
          '-' +
          (checkbox.name || checkbox.licenseName || checkbox.instrumentName)
        "
        :checkbox="
          brokerData.find((item) => checkbox.id === item.id)
            ? brokerData.find((item) => checkbox.id === item.id)
            : type === 'instruments'
            ? instruments.find(
                (item) =>
                  checkbox.id === item.id || checkbox.instrumentId === item.id
              )
            : licenses.find((item) => checkbox.id === item.id)
        "
        :checked="
          checkbox && (checkbox.instrumentId || checkbox.licenseId)
            ? true
            : false
        "
        :inputType="type === 'instruments' ? 'number' : null"
        :value="
          !$apollo.loading && checkbox[checkId] ? actualValue(checkbox) : null
        "
        :changedValue="changedData(checkbox)"
        :id="checkbox.id"
        :checker="
          $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
            ? 'unset'
            : null
        "
        @clear-search-field="query = ''"
      />
    </div>
    <p
      class="checkbox_select__no_results"
      v-show="typeof actualData === 'string'"
    >
      No result for query: "{{ query }}"
    </p>
  </fieldset>
</template>

<script>
import CompairingService from "@/services/CompairingService";

import Checkbox from "../formFields/Checkbox";
import { licenses, instruments } from "@/components/graphQL/brokerQueries";
import { mapGetters } from "vuex";

export default {
  name: "CheckboxSelect",
  apollo: {
    licenses: licenses,
    instruments: instruments,
  },
  props: {
    brokerData: Array,
    changesBrokerData: Array,
    checker: String,
    type: String,
    title: String,
    subtitle: String,
  },
  components: {
    Checkbox,
  },
  data() {
    return {
      showAll: false,
      compairingService: new CompairingService(),
      minHeight: 100,
      baseHeight: 100,
      itemHeight: 47,
      checkId: "",
      checkValue: "",
      query: "",
    };
  },
  computed: {
    ...mapGetters(["getCurrentMode", "getSelectedTab"]),
    actualData: function () {
      let actualData = [];
      let dataToSort = this[this.type];

      if (dataToSort) {
        if (this.getCurrentMode === "review") {
          actualData =
            this.brokerData && this.changesBrokerData === undefined
              ? this.brokerData
              : this.brokerData === undefined && this.changesBrokerData
              ? this.changesBrokerData
              : this.brokerData && this.changesBrokerData
              ? this.compairingService.getComparedInputData(
                  this.brokerData.length ? this.brokerData : [],
                  this.changesBrokerData ? this.changesBrokerData : [],
                  "checkboxes"
                )
              : null;
        } else if (this.getSelectedTab.title === "logs") {
          actualData =
            this.brokerData && this.changesBrokerData === undefined
              ? this.brokerData
              : this.brokerData === undefined && this.changesBrokerData
              ? this.changesBrokerData
              : this.brokerData && this.changesBrokerData
              ? this.compairingService.getComparedInputData(
                  this.brokerData,
                  this.changesBrokerData,
                  "checkboxes"
                )
              : null;
        } else {
          dataToSort.forEach((item) => {
            let founded = this.brokerData.find(
              (selectedItem) => selectedItem[this.checkId] === item.id
            );
            if (founded) {
              for (let key in founded) {
                item[key] = founded[key];
              }
            }
            actualData.push(item);
          });
        }
      }

      if (actualData?.length > 1) {
        let value = this.checkValue;
        actualData.sort(function (a, b) {
          let first = !!a[value];
          let second = !!b[value];
          if (first < second) {
            return 1;
          }
          if (first > second) {
            return -1;
          }
          return 0;
        });
      }
      if (this.query) {
        return actualData.filter((item) =>
          item.name.toLowerCase().includes(this.query.toLowerCase())
        ).length
          ? actualData.filter((item) =>
              item.name.toLowerCase().includes(this.query.toLowerCase())
            )
          : (actualData = "No results");
      } else {
        return actualData;
      }
    },
  },
  mounted() {
    this.getCurrentMode === "review" ? (this.showAll = true) : null;
    this.checkId =
      this.type === "licenses"
        ? "licenseId"
        : this.type === "instruments"
        ? "instrumentId"
        : "id";
    this.checkValue =
      this.type === "licenses"
        ? "licenseNumber"
        : this.type === "instruments"
        ? "instrumentName"
        : "value";
  },
  methods: {
    actualValue: function (checkbox) {
      if (checkbox[this.checkId]) {
        return this.brokerData.length >= 0
          ? this.brokerData.find(
              (item) => item[this.checkId] === checkbox[this.checkId]
            )
            ? this.brokerData.find(
                (item) => item[this.checkId] === checkbox[this.checkId]
              )[this.checkValue]
            : ""
          : "";
      }
    },
    changedData(checkbox) {
      if (this.type === "licenses") {
        const thisCheckBox = this.changesBrokerData?.find(
          (item) => item[this.checkId] === checkbox[this.checkId]
        );
        if (thisCheckBox) {
          return thisCheckBox[this.checkValue];
        } else {
          return this.getCurrentMode !== "review"
            ? {
                id: null,
                licenseName: null,
                licenseNumber: null,
                licenseId: null,
              }
            : null;
        }
      } else {
        return this.changesBrokerData?.find(
          (item) => item[this.checkId] === checkbox[this.checkId]
        );
      }
    },
  },
  updated() {
    this.$nextTick(function () {
      if (document.querySelectorAll(".checkbox--checked").length) {
        this.minHeight =
          this.baseHeight +
          this.itemHeight *
            document.querySelectorAll(".checkbox--checked").length;
      }
    });
  },
};
</script>
